import "./alertConfirmation.modules.scss";
import Button from "../../share/button/Button";

export default function AlertConfirmation( { description, onClickCancelar, onClickAceptar } ) {
  return (
    <div className="modalAlert">
      {description && <p>{description}</p>}
      <div className="containerButtons">
        {/* <Button onClick={() => onClickCancelar()} type="button" className="buttonCancelar">Cancelar</Button> */}
        <Button onClick={() => onClickCancelar()} type="button" className="buttonCancelar">Cancelar</Button>
        <Button onClick={() => onClickAceptar()} type="button" className="buttonContinuar">Continuar</Button>
      </div>
    </div>
  );
}
