import "./header.modules.scss"

export default function Header() {
  return (
    <div className="header">
      <h1>Transporte: Reserva de viajes</h1>
      <h4>
        Los horarios son aproximados, recomendamos estar 5 minutos antes.<br /> En los
        horarios de mayor concurrencia, incorporamos un servicio de minibus.
      </h4>
    </div>
  );
}
