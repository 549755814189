import { useContext } from "react";
import CustomModal from "../../../components/customModal/customModal";
import { useModalWithData } from "../../../hooks/useModalWithData";
import AlertConfirmation from "../alertConfirmation/alertConfirmation";
import { useAllBooking, useCancelMyBooking } from "../../../hooks/useBooking";
import "./modalAllReservation.modules.scss";
import BoxReservationAll from "./components/boxReservation";
import Loading from "../../../components/loading/loading";
import getTokenContext from "../../../context/token.context";
import ContextSnackbar from "../../../context/contextSnackbar";

export default function ModalAllReservation({ date = "" }) {
  const getContextLoading = useContext(ContextSnackbar);

  const tokenContext = useContext(getTokenContext);
  const [setIsModalOpened, isModalOpened] = useModalWithData("");

  const { data: allBookingData, isLoading: isLoadingAllBooking } =
    useAllBooking(tokenContext, date);
  const { mutate: cancelMyBookingById } = useCancelMyBooking(tokenContext);

  const deleteReservation = (idBooking) => {
    cancelMyBookingById(idBooking);
    getContextLoading.setSnackBar({
      status: "success",
      message: "La reserva se elimino correctamente",
    });
    setIsModalOpened("");
  };

  return (
    <div className="modalAllReservationContainer">
      <CustomModal
        isActive={isModalOpened !== ""}
        handleClose={() => {
          setIsModalOpened("");
        }}
        size="m"
      >
        <CustomModal.Header>
          <h3>¿Está seguro de que desea descartar la reserva?</h3>
        </CustomModal.Header>
        <CustomModal.Body>
          <AlertConfirmation
            onClickCancelar={() => {
              setIsModalOpened("");
            }}
            onClickAceptar={() => {
              deleteReservation(isModalOpened);
            }}
          />
        </CustomModal.Body>
      </CustomModal>
      <h4>
        Recorda que todos los horarios son aproximados, recomendamos que estes 5
        minutos antes
      </h4>
      {!isLoadingAllBooking && allBookingData ? (
        allBookingData.length > 0 ? (
          allBookingData.map((reservation) => (
            <BoxReservationAll
              key={reservation.Id}
              reservation={reservation}
              setIsModalOpened={setIsModalOpened}
            />
          ))
        ) : (
          <p>No hay registros históricos</p>
        )
      ) : (
        <Loading />
      )}
    </div>
  );
}
