import {createContext, useContext} from "react";
import styles from "./customModal.module.scss";
import clsx from "clsx";

const ModalContext = createContext({});

const ModalContents = ({ isActive, children, handleClose, size = "m" }) => {
  if (isActive === true) {
    return (
      <ModalContext.Provider
        value={{
          handleClose,
        }}
      >
        <div className={clsx([styles["modal__overlay"]])} onClick={handleClose}>
          <div
            className={clsx([styles[`modal`], styles[`modal-size-${size}`]])}
            onClick={(event) => event.stopPropagation()}
          >
            {children}
          </div>
        </div>
      </ModalContext.Provider>
    );
  } else {
    return null;
  }
};

const ModalHeader = ({ children, className }) => {
  const style = clsx([styles["modal-header"], className]);
  const { handleClose } = useContext(ModalContext);

  return (
    <header className={style}>
      <div className={clsx([styles["modal-header-info"]])}>{children}</div>
      <button
        onClick={() => handleClose()}
        className={clsx([styles["modal-header-close"]])}
        aria-label="close"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 4L12 12M4 12L12 4L4 12Z"
            stroke="#94A4FF"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </header>
  );
};

const ModalBody = ({ children, className }) => {
  const style = clsx([styles["modal-body"], className]);
  return <div className={style}>{children}</div>;
};

const ModalFooter = ({ children, className }) => {
  const style = clsx([styles["modal-footer"], className]);
  return (
    <div as="footer" className={style}>
      {children}
    </div>
  );
};

const ModalNamespace = Object.assign(ModalContents, {
  Footer: ModalFooter,
  Header: ModalHeader,
  Body: ModalBody,
});

ModalContents.displayName = "ModalContents";
ModalFooter.displayName = "ModalFooter";
ModalHeader.displayName = "ModalHeader";
ModalBody.displayName = "ModalBody";
export { ModalNamespace as ModalContents };
export default ModalNamespace;

// import React from "react";
// import "./customModal.scoped.scss";

// export default function ModalContents({
//   isActive,
//   children,
//   handleClose,
//   size = "m",
// }) {
//   if (isActive === true) {
//     return (
//       <>
//         <div className="modal__overlay" onClick={handleClose}>
//           <div
//             className={`modal modal-size-${size}`}
//             onClick={(event) => event.stopPropagation()}
//           >
//             <button className="material-icons" onClick={handleClose}>
//               x
//             </button>
//             {children}
//           </div>
//         </div>
//       </>
//     );
//   } else {
//     return null;
//   }
// }
