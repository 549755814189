import { useContext } from "react";
import "./boxReservation.modules.scss";
import Button from "../../../share/button/Button";
import { API_URL } from "../../../../constants/config";
import getTokenContext from "../../../../context/token.context";
import { getVoucherReservation } from "../../../../hooks/useBooking";

export default function BoxReservationAll({
  reservation,
  setIsModalOpened,
  key,
}) {
  const url = `${API_URL}/travelBooking/ticket/${reservation.Id}`;
  return (
    <div className="myReservations" key={key}>
      <div className="reservationInfo">
        <div className="infoLeft">
          <span>
            Pasajero: <strong>{reservation.Passenger}</strong>
          </span>
          <span>
            Punto de partida: <strong>{reservation.Stop}</strong>
          </span>
          <span>
            DNI: <strong>{reservation.Identification}</strong>
          </span>
        </div>
        <div className="infoRight">
          <span>
            Horario: <strong>{reservation.Time}</strong>
          </span>
          <span>
            Fecha: <strong>{reservation.Date}</strong>
          </span>
        </div>
      </div>
      <div className="callToAction">
        {reservation.Canceled === false ? (
            <>
            {reservation.CanDelete === true ? (
              <Button
                type="button"
                onClick={() => {
                  setIsModalOpened(reservation.Id);
                }}
              >
                Eliminar reserva
              </Button>
            ) : (
              <p>
                El tiempo para cancelar la reserva ha finalizado
              </p>
            )}
            <a type="button" target="blank" href={url}>
              Descargar comprobante
            </a>
            </>
          ):( 
          <p className="canceled-status">
            Reserva cancelada
          </p>
          )
        }
      </div>
    </div>
  );
}
