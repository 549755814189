import styles from "./modalTimes.module.scss";
import { useTimeStop } from "../../../hooks/useBooking";
import { useFormik, FormikProvider } from "formik";
import * as yup from "yup";
import { STRINGS } from "../../../constants/strings";
import disabledTime from "../../../images/VectordisabledTime.svg";
import { useEffect } from "react";
import clsx from "clsx";
import Button from "../../share/button/Button";

export default function ModalTimes({
  modifyItem,
  tokenContext,
  cancelClick,
  previewBookingData,
  getPreviewModification,
}) {
  const { data: timeStopByStop, mutate: mutateTimeStop } = useTimeStop(
    modifyItem.TravelBookingRouteId,
    modifyItem.TravelBookingRouteStopId,
    `${modifyItem.Year}${
      modifyItem.Month < 10 ? `0${modifyItem.Month}` : modifyItem.Month
    }${
      modifyItem.DayNumber < 10
        ? `0${modifyItem.DayNumber}`
        : modifyItem.DayNumber
    }`,
    tokenContext
  );

  useEffect(() => {
    mutateTimeStop();
  }, [mutateTimeStop]);

  const formik = useFormik({
    initialValues: {
      timeModal: undefined,
    },
    onSubmit: (formikData) => {
      let modifyPreview = null;
      
      if (formikData.timeModal == -1) {

        modifyPreview = previewBookingData.filter(
          (previewModify) => previewModify.DayNumber !== modifyItem.DayNumber
        );
      } else {
        modifyPreview = previewBookingData.map((preview) => {
          if (preview.DayNumber === modifyItem.DayNumber) {
            return {
              ...preview,
              TravelBookingRouteTimeStopId: Number(
                formikData.timeModal.substr(
                  0,
                  formikData.timeModal.indexOf(" ", 1)
                )
              ),
              TravelBookingScheduleId: Number(
                formikData.timeModal.substr(
                  formikData.timeModal.indexOf(" ", 1) + 1
                )
              ),
            };
          } else {
            return preview;
          }
        });
      }
      getPreviewModification(modifyPreview);
      //crear funcion para llamar a previewNuevo.
    },
    validationSchema: yup.object({
      timeModal: yup.number(STRINGS.type).required(STRINGS.required),
    }),
  });

  return (
    <FormikProvider value={formik}>
      <form
        className={clsx([styles["container-times"]])}
        onSubmit={formik.handleSubmit}
      >
        <div>
          {timeStopByStop &&
            timeStopByStop.map((stopTime, i) =>
              stopTime.Available ? (
                <div
                  key={stopTime.Id}
                  className={clsx([styles["timeAvailable"]])}
                >
                  <>
                    <input
                      type="radio"
                      name="timeModal"
                      value={
                        stopTime.Id + " " + stopTime.TravelBookingScheduleId
                      }
                      onChange={formik.handleChange}
                    />
                    <label htmlFor="timeModal"> {stopTime.Time} </label>
                  </>
                </div>
              ) : (
                <div
                  key={stopTime.Id}
                  className={clsx([styles["timeNoAvailable"]])}
                >
                  <img alt="disabledTime" src={disabledTime} />
                  <label>{stopTime.Time}</label>
                </div>
              )
            )}
          <div className={clsx([styles["timeAvailable"]])}>
            <input
              type="radio"
              name="timeModal"
              value={-1}
              onChange={formik.handleChange}
            />
            <label htmlFor="timeModal"> Eliminar reserva </label>
          </div>
        </div>
        <div className={clsx([styles["containerButtons"]])}>
          <Button
            onClick={() => cancelClick()}
            type="button"
            className={clsx([styles["buttonCancelar"]])}
          >
            Cancelar
          </Button>
          <Button type="submit" className={clsx([styles["buttonContinuar"]])}>
            Continuar
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
}
