export const getDayByIdDay = (idDay) => {
  return daysOfWeeks.filter((dayOfWeek) => dayOfWeek.id === idDay)[0].day;
};

export const daysOfWeeks = [
  {
    id: 1,
    day: "Lunes",
  },
  {
    id: 2,
    day: "Martes",
  },
  {
    id: 3,
    day: "Miercoles",
  },
  {
    id: 4,
    day: "Jueves",
  },
  {
    id: 5,
    day: "Viernes",
  },
];

export const recurrenyObj = [
  {
    id: 0,
    name: "Ùnica",
  },
  {
    id: 2,
    name: "Semanal",
  },
  {
    id: 3,
    name: "Mensual",
  },
];

export const monthNames = [
  "",
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];
