import { useContext } from "react";
import css from "./modalHistoryPassager.module.scss";
import { useHistoryPassager } from "../../../hooks/useBooking";
import getTokenContext from "../../../context/token.context";

export default function ModalHistoryPassager({ formik, setIsModalOpened }) {
  const tokenContext = useContext(getTokenContext);

  const { data: historyPassager } = useHistoryPassager(tokenContext);

  const setPassager = (passager) => {
    formik.setValues((prevState) => ({
      ...prevState,
      name: passager.FirstName,
      lastName: passager.LastName,
      dni: passager.Identification,
    }));
    setIsModalOpened(false);
  };

  return (
    <div className={css.boxPassager}>
      {historyPassager && historyPassager.length > 0 ? (
        <>
          {historyPassager.map((passager, i) => (
            <button
              onClick={() => setPassager(passager)}
              className={`${css.containerPassager} ${historyPassager.length == i + 1 && css.lastButton}`}
            >
              <div className={css.information}>
                <div>
                  {` ${passager.FirstName} ${passager.LastName} | ${passager.Identification}`}
                </div>
              </div>
            </button>
          ))}
        </>
      ) : (
        <h2>No hay registros históricos</h2>
      )}
    </div>
  );
}
