import { useEffect, useContext } from "react";
import "./modalReservation.modules.scss";
import CustomModal from "../../../components/customModal/customModal";
import { useModalWithData } from "../../../hooks/useModalWithData";
import { daysOfWeeks, getDayByIdDay } from "../../../constants/objects";
import { useFormik, FormikProvider, Field, ErrorMessage } from "formik";
import {
  useStopByRoute,
  useRoutes,
  useTimeStop,
  useUnit,
} from "../../../hooks/useBooking";
import * as yup from "yup";
import Button from "../../share/button/Button";
import { recurrenyObj } from "../../../constants/objects";
import { STRINGS } from "../../../constants/strings";
import disabledTime from "../../../images/VectordisabledTime.svg";
import getTokenContext from "../../../context/token.context";
import ModalHistoryPassager from "../modalHistoryPassager/modalHistoryPassager";

export default function ModalReservation({
  width,
  currentDay,
  currentDayWeek,
  currentMonth,
  currentYear,
  cancelClick,
  getPreview,
}) {

  const year = currentYear;
  const filtermonth = currentMonth;

  const breakpoint = 1550;
  const [setIsModalOpened, isModalOpened] = useModalWithData();

  const tokenContext = useContext(getTokenContext);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      lastName: "",
      dni: "",
      route: "",
      stop: "",
      time: "",
      recurrency: "0",
      recurrencyDays: [],
      unit: "",
    },
    onSubmit: (formikData) => {
      getPreview({
        ...formikData,
        month: filtermonth,
        day: currentDay,
      });
    },
    validationSchema: yup.object({
      name: yup
        .string(STRINGS.type)
        .nullable(STRINGS.type)
        .required(STRINGS.required),
      lastName: yup
        .string(STRINGS.type)
        .nullable(STRINGS.type)
        .required(STRINGS.required),
      dni: yup
        .string()
        .matches(/^[0-9]+$/, "Solo se aceptan numeros positivos")
        .min(7, STRINGS.formatDNI)
        .max(8, STRINGS.formatDNI)
        .required(STRINGS.required),
      route: yup.number(STRINGS.type).required(STRINGS.required),
      stop: yup.number(STRINGS.type).required(STRINGS.required),
      time: yup.number(STRINGS.type).required(STRINGS.required),
      recurrency: yup.number(STRINGS.type).required(STRINGS.required),
      recurrencyDays: yup.array().when("recurrency", {
        is: (recurrency) => recurrency === 3,
        then: yup.array().of(yup.number()).min(1, STRINGS.required),
      }),
      // .array()
      // .of(yup.number()).min(1,(STRINGS.required)),
      // .required(STRINGS.required),
      unit: yup.number(STRINGS.type).required(STRINGS.required),
    }),
  });

  

  const { data: Unit } = useUnit(tokenContext);

  const { data: routes } = useRoutes(tokenContext);

  const { data: StopsByRoute, mutate: mutateStopByRoute } = useStopByRoute(
    formik.values.route,
    tokenContext
  );

  const {
    data: timeStopByStop,
    mutate: mutateTimeStop,
    reset: resetTimeStop,
  } = useTimeStop(
    formik.values.route,
    formik.values.stop,
    `${year}${filtermonth < 10 ? `0${filtermonth}` : filtermonth}${currentDay}`,
    tokenContext
  );

  useEffect(() => {
    if (formik.values.route !== "" && formik.values.route !== undefined) {
      resetTimeStop();
      mutateStopByRoute(
        {},
        {
          onSuccess: (data) => {
            formik.values.stop = data[0].Id;
          },
        }
      );
    }
  }, [formik.values.route, resetTimeStop, mutateStopByRoute]);
  useEffect(() => {
    if (formik.values.stop !== "" && formik.values.stop !== undefined) {
      mutateTimeStop();
    }
  }, [formik.values.stop, StopsByRoute, mutateTimeStop]);

  return (
    <FormikProvider value={formik}>
      <CustomModal
        isActive={!!isModalOpened}
        handleClose={() => {
          setIsModalOpened(false);
        }}
        size="m"
      >
        <CustomModal.Header>
          <h3>Seleccione pasajero</h3>
        </CustomModal.Header>
        <CustomModal.Body>
          <ModalHistoryPassager
            formik={formik}
            setIsModalOpened={setIsModalOpened}
          />
        </CustomModal.Body>
      </CustomModal>
      <form
        className="modalReservationContainer"
        onSubmit={formik.handleSubmit}
      >
        <div className="dataUser">
          <div className="header">
            <h4>Datos pasajero</h4>
            <Button type="button" onClick={() => setIsModalOpened(true)}>
              Históricos
            </Button>
          </div>
          <div className="containerInputs">
            <div className="wrapInputs">
              <Field type="text" name="name" placeholder="Nombre" />
              <ErrorMessage component="span" name="name" />
            </div>
            <div className="wrapInputs">
              <Field type="text" name="lastName" placeholder="Apellido" />
              <ErrorMessage component="span" name="lastName" />
            </div>
            <div className="wrapInputs">
              <Field
                type="number"
                name="dni"
                placeholder="DNI"
                pattern="[0-9]{8}"
              />
              <ErrorMessage component="span" name="dni" />
            </div>
          </div>
        </div>
        <div className="stopPoint">
          <h4>Punto de partida</h4>
          <div>
            <div className="containerInputs">
              <div className="wrapInputs">
                <Field as="select" name="route">
                  <option disabled selected value="">
                    Seleccione un ramal
                  </option>
                  {routes &&
                    routes.map((route) => (
                      <option key={route.Id} value={route.Id}>
                        {route.Name}
                      </option>
                    ))}
                </Field>
                <ErrorMessage component="span" name="route" />
              </div>
              <div className="wrapInputs">
                <Field as="select" name="stop">
                  <option disabled selected value="">
                    Seleccione una parada
                  </option>
                  {StopsByRoute &&
                    StopsByRoute.map((stopRoute) => (
                      <option key={stopRoute.Id} value={stopRoute.Id}>
                        {stopRoute.Name}
                      </option>
                    ))}
                </Field>
                <ErrorMessage component="span" name="stop" />
              </div>
              <div className="wrapInputs">
                <Field as="select" name="unit">
                  <option disabled selected value="">
                    Seleccione un lote
                  </option>
                  {Unit &&
                    Unit.map((unit) => (
                      <option key={unit.Id} value={unit.Id}>
                        {unit.Code}
                      </option>
                    ))}
                </Field>
                <ErrorMessage component="span" name="unit" />
              </div>
              {/* <div className="wrapInputs">
                <p>
                  Recuerde que los horarios son aproximados, recomendamos estar
                  5 minutos antes.
                </p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="colReservationContainer">
          <h4>Horarios: </h4>
          <div>
            {timeStopByStop ? (
              typeof timeStopByStop !== "string" ? (
                timeStopByStop.map((stopTime, i) =>
                  stopTime.Available ? (
                    <div key={stopTime.Id} className="timeAvailable">
                      <>
                        <input
                          type="radio"
                          name="time"
                          value={stopTime.Id}
                          onChange={formik.handleChange}
                        />
                        <label htmlFor="time"> {stopTime.Time} </label>
                      </>
                    </div>
                  ) : (
                    <div key={stopTime.Id} className="timeNoAvailable">
                      <img alt="disabledTime" src={disabledTime} />
                      <label>{stopTime.Time}</label>
                    </div>
                  )
                )
              ) : (
                <p>{timeStopByStop}</p>
              )
            ) : (
              <p>Debe seleccionar una parada para poder elegir un horario.</p>
            )}
          </div>

          <ErrorMessage component="span" name="time" />
        </div>
        <div className="recurrency">
          <h4>Recurrencia</h4>
          <div className="wrapRecurrency">
            <div>
              <Field as="select" name="recurrency">
                {recurrenyObj.map((recurrenyObj) => (
                  <option key={recurrenyObj.id} value={recurrenyObj.id}>
                    {recurrenyObj.name}
                  </option>
                ))}
              </Field>

              <ErrorMessage component="span" name="recurrency" />
            </div>
            <div>
              {formik.values.recurrency === "3" && (
                <>
                  <div className="listOfDays">
                    {daysOfWeeks.map((dayOfWeek) => {
                      return (
                        <div
                          className={`wrapDays ${
                            formik.values.recurrencyDays.includes(
                              dayOfWeek.id.toString()
                            )
                              ? "activeDay"
                              : ""
                          }`}
                        >
                          <input
                            key={dayOfWeek.id}
                            type="checkbox"
                            name="recurrencyDays"
                            value={dayOfWeek.id}
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="recurrencyDays">
                            {" "}
                            {width > breakpoint
                              ? dayOfWeek.day
                              : dayOfWeek.day.substring(0, 3)}{" "}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <ErrorMessage component="span" name="recurrencyDays" />
                </>
              )}
            </div>
          </div>

          <p>{`${
            formik.values.recurrency === "0"
              ? `El turno sera para la fecha ${currentDay}/${filtermonth}/${year}`
              : formik.values.recurrency === "2"
              ? `El turno sera para los dias ${getDayByIdDay(currentDayWeek)} de todo el mes`
              : ``
          }`}</p>
        </div>
        <div className="containerButtons">
          <Button
            onClick={() => cancelClick()}
            type="button"
            className="buttonCancelar"
          >
            Cancelar
          </Button>
          <Button type="submit" className="buttonContinuar">
            Continuar
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
}
